import {
  Slot,
  component$,
  createContextId,
  useContext,
  useContextProvider,
} from '@builder.io/qwik';
import {
  blockViewSectionClass,
  embedLinkBlockContainerAppearanceClass,
  embedLinkBlockContainerWrapperClass,
  embedLinkBlockDescriptionClass,
  embedLinkBlockFaviconClass,
  embedLinkBlockHostClass,
  embedLinkBlockImgClass,
  embedLinkBlockMetadataClass,
  embedLinkBlockThumbnailClass,
  embedLinkBlockTitleClass,
  imageBlockContainerClass,
  imageBlockImageClass,
  leafBlockViewClass,
  tableClass,
  tdThClass,
  textBlockClass,
  textOpCodeClass,
  textOpDelClass,
  textOpStrongClass,
  thClass,
  ulClass,
  ulNonTopLevelClass,
} from '@rewiki/front-share/src/components/BlockView.css';
import { embedLinkFaviconUrl } from '@rewiki/front-share/src/lib/embedLink';
import type { EntryEntity as Entry } from '@rewiki/isomorphic/entity/__generated__/Entry';
import type { SiteEntity as Site } from '@rewiki/isomorphic/entity/__generated__/Site';
import { imageUrl, resizedSize } from '@rewiki/isomorphic/entity/image';
import { type OutlineBlock, outlineBlockKey } from '@rewiki/isomorphic/lib/reditor/outline';
import type {
  BlockJSON,
  Delta,
  DividerBlockJSON,
  EmbedLinkBlockJSON,
  EntryLinkId,
  ImageBlockJSON,
  Op,
  TableBlockJSON,
  TableCellBlockJSON,
  TextBlockJSON,
  TitleBlockJSON,
} from '@rewiki/isomorphic/lib/reditor/sdoc';
import {
  blockTypeDivider,
  blockTypeEmbedLink,
  blockTypeImage,
  blockTypeTable,
  blockTypeTableCell,
  blockTypeText,
  blockTypeTitle,
  cellKey,
} from '@rewiki/isomorphic/lib/reditor/sdoc';
import { exhaustiveCheck } from '@rewiki/isomorphic/lib/utils';
import { rr } from '@rewiki/isomorphic/routes';
import type { EntryId } from '@rewiki/isomorphic/scalar/__generated__/EntryId';
import { isoEntryId } from '@rewiki/isomorphic/scalar/__generated__/EntryId';
import { isoImageId } from '@rewiki/isomorphic/scalar/__generated__/ImageId';
import IconPhArrowSquareOut from '~icons/ph/arrow-square-out';
import { useShareRootContext } from '../context';

const BlockViewContext = createContextId<BlockViewContextValue>('block-view');

type BlockViewContextValue = Readonly<{
  site: Pick<Site, 'subdomain' | 'id'>;
  referenceById: ReadonlyMap<EntryId, Pick<Entry, 'id' | 'path' | 'title'>>;
  headingLevelOffset: number; // 0 だと変化なし. 1 だと h2 が h3 になる.
}>;

const EntryLinkOpView = component$<{ readonly entryId: EntryLinkId }>((props) => {
  const ctx = useContext(BlockViewContext);
  const entry = ctx.referenceById.get(isoEntryId.wrap(props.entryId));
  // clipboard 用
  const dataRewiki = JSON.stringify({
    type: 'entryLink',
    entryId: props.entryId,
    siteId: ctx.site.id,
  });
  return (
    <a
      data-rewiki={dataRewiki}
      {...(entry
        ? {
            href: rr.entry(ctx.site.subdomain, entry.path).path,
          }
        : {})}
    >
      {entry ? entry.title : `[${messages.deletedPageTitle}]`}
    </a>
  );
});

const TextOpViewLink = component$<{
  readonly op: Op;
}>((props) =>
  props.op.attributes?.link ? (
    <a href={props.op.attributes.link}>
      <Slot />
      <IconPhArrowSquareOut class='icon' />
    </a>
  ) : (
    <Slot />
  ),
);
const TextOpViewStrong = component$<{
  readonly op: Op;
}>((props) =>
  props.op.attributes?.strong ? (
    <em class={textOpStrongClass}>
      <Slot />
    </em>
  ) : (
    <Slot />
  ),
);
const TextOpViewDel = component$<{
  readonly op: Op;
}>((props) =>
  props.op.attributes?.del ? (
    <del class={textOpDelClass}>
      <Slot />
    </del>
  ) : (
    <Slot />
  ),
);
const TextOpViewCode = component$<{
  readonly op: Op;
}>((props) =>
  props.op.attributes?.code ? (
    <code class={textOpCodeClass}>
      <Slot />
    </code>
  ) : (
    <Slot />
  ),
);

const TextOpView = component$<{
  readonly op: Op;
  readonly isTitle?: boolean;
}>((props) => {
  const op = props.op;
  const isTitle = props.isTitle;
  if (typeof op.insert !== 'string') {
    return isTitle ? <></> : <EntryLinkOpView entryId={op.insert.id} />;
  }
  return (
    <TextOpViewLink op={op}>
      <TextOpViewStrong op={op}>
        <TextOpViewDel op={op}>
          <TextOpViewCode op={op}>{op.insert}</TextOpViewCode>
        </TextOpViewDel>
      </TextOpViewStrong>
    </TextOpViewLink>
  );
});

// TODO: br ではない気もする…?
const TextView = component$<{
  readonly text: Delta;
  readonly isTitle?: boolean;
}>((props) => {
  return props.text.length === 0 ? (
    <br />
  ) : (
    <>
      {props.text.map((op, i) => (
        <TextOpView key={i} op={op} isTitle={props.isTitle} />
      ))}
    </>
  );
});

const TitleBlockView = component$<{
  readonly block: TitleBlockJSON;
}>(() => <></>); // ブロックの外でレンダリングしてるので不要

const TextBlockView = component$<{
  readonly block: TextBlockJSON;
  readonly isList?: boolean;
}>((props) => {
  const ctx = useContext(BlockViewContext);
  const isList = props.isList;
  const block = props.block;
  if (isList || block.headingLevel === undefined) {
    return (
      <p class={textBlockClass}>
        <TextView text={block.text} />
      </p>
    );
  }
  const headingLevel = block.headingLevel + ctx.headingLevelOffset;
  const Tag =
    headingLevel <= 1
      ? 'h2'
      : headingLevel <= 4
        ? (`h${headingLevel + 1}` as 'h3' | 'h4' | 'h5')
        : 'h6';
  return (
    <Tag id={block.id}>
      <TextView text={block.text} />
    </Tag>
  );
});

const DividerBlockView = component$<{
  readonly block: DividerBlockJSON;
}>(() => <hr />);

const TableBlockView = component$<{
  readonly block: TableBlockJSON;
}>((props) => {
  const block = props.block;
  const dataRewiki = JSON.stringify({
    type: 'table',
    rowHeader: block.rowHeader,
    columnHeader: block.columnHeader,
  });

  return (
    <table class={tableClass} data-rewiki={dataRewiki}>
      <TableBlockViewThead block={block} />
      <TableBlockViewTbody block={block} />
    </table>
  );
});

const TableBlockViewThead = component$<{
  readonly block: TableBlockJSON;
}>(
  (props) =>
    props.block.columnHeader &&
    props.block.rowIds.length > 0 && (
      <thead>
        <TableBlockViewTr block={props.block} rowId={props.block.rowIds[0]} rowIndex={0} />
      </thead>
    ),
);

const TableBlockViewTbody = component$<{
  readonly block: TableBlockJSON;
}>((props) =>
  props.block.columnHeader ? (
    props.block.rowIds.length >= 2 && (
      <tbody>
        {props.block.rowIds.map(
          (rowId, rowIndex) =>
            rowIndex !== 0 && (
              <TableBlockViewTr key={rowId} block={props.block} rowId={rowId} rowIndex={rowIndex} />
            ),
        )}
      </tbody>
    )
  ) : (
    <tbody>
      {props.block.rowIds.map((rowId, rowIndex) => (
        <TableBlockViewTr key={rowId} block={props.block} rowId={rowId} rowIndex={rowIndex} />
      ))}
    </tbody>
  ),
);

const TableBlockViewTr = component$<{
  readonly block: TableBlockJSON;
  rowId: string;
  rowIndex: number;
}>((props) => (
  <tr>
    {props.block.columnIds.map((columnId, columnIndex) => (
      <TableCellBlockView
        key={columnId}
        block={props.block.cells[cellKey(props.rowId, columnId)]}
        headerScope={
          props.rowIndex === 0 && props.block.columnHeader
            ? 'col'
            : columnIndex === 0 && props.block.rowHeader
              ? 'row'
              : undefined
        }
      />
    ))}
  </tr>
));

const TableCellBlockView = component$<{
  readonly block: TableCellBlockJSON;
  readonly headerScope: 'col' | 'row' | undefined;
}>((props) => {
  const Tag = props.headerScope !== undefined ? 'th' : 'td';
  return (
    <Tag
      scope={props.headerScope}
      class={[tdThClass, { [thClass]: props.headerScope !== undefined }]}
    >
      {(props.block.children ?? []).map((child) => (
        <LeafBlockView key={child.id} block={child} />
      ))}
    </Tag>
  );
});

const ImageBlockView = component$<{ readonly block: ImageBlockJSON }>((props) => {
  const ctx = useContext(BlockViewContext);
  const block = props.block;
  const { width, height } =
    block.width && block.height
      ? resizedSize(block.width, block.height)
      : { width: undefined, height: undefined };

  // clipboard 用
  const dataRewiki = JSON.stringify({
    type: 'image',
    imageId: block.imageId,
    siteId: ctx.site.id,
  });

  const shareRoot = useShareRootContext();
  return (
    <div class={imageBlockContainerClass} data-rewiki={dataRewiki}>
      <img
        class={imageBlockImageClass}
        width={width}
        height={height}
        src={imageUrl(
          {
            siteId: ctx.site.id,
            id: isoImageId.wrap(block.imageId),
            usageType: 'BLOCK',
          },
          shareRoot.imagesOrigin,
        )}
      />
    </div>
  );
});

const EmbedLinkBlockView = component$<{ readonly block: EmbedLinkBlockJSON }>((props) => {
  const ctx = useContext(BlockViewContext);
  const shareRoot = useShareRootContext();
  const block = props.block;
  // clipboard 用
  const dataRewiki = JSON.stringify({
    type: 'embedLink',
    url: block.url,
    imageUrl: block.imageUrl,
    imageId: block.imageId,
    imageAlt: block.imageAlt,
    title: block.title,
    description: block.description,
    siteName: block.siteName,
  });
  const host = new URL(block.url).host;

  return (
    <div data-rewiki={dataRewiki} class={embedLinkBlockContainerWrapperClass}>
      <a href={block.url} class={embedLinkBlockContainerAppearanceClass}>
        <div class={embedLinkBlockMetadataClass}>
          <h6 class={embedLinkBlockTitleClass}>{block.title}</h6>
          <div class={embedLinkBlockDescriptionClass}>{block.description}</div>
          <div class={embedLinkBlockHostClass}>
            <img
              role='presentation'
              alt='favicon'
              class={embedLinkBlockFaviconClass}
              referrerPolicy='no-referrer'
              src={embedLinkFaviconUrl(host)}
              width='16'
              height='16'
            />
            {host}
          </div>
        </div>
        {block.imageId && (
          <div class={embedLinkBlockThumbnailClass}>
            <img
              class={embedLinkBlockImgClass}
              src={imageUrl(
                {
                  siteId: ctx.site.id,
                  id: isoImageId.wrap(block.imageId),
                  usageType: 'EMBED_LINK',
                },
                shareRoot.imagesOrigin,
              )}
            />
          </div>
        )}
      </a>
    </div>
  );
});

type LeafBlockViewProps = Readonly<{
  block: BlockJSON;
  isList?: boolean;
}>;
const LeafBlockView = component$<LeafBlockViewProps>((props) => (
  <div class={leafBlockViewClass}>
    {(() => {
      switch (props.block.__typename) {
        case blockTypeTitle:
          return <TitleBlockView block={props.block} />;
        case blockTypeText:
          return <TextBlockView block={props.block} isList={props.isList} />;
        case blockTypeDivider:
          return <DividerBlockView block={props.block} />;
        case blockTypeTable:
          return <TableBlockView block={props.block} />;
        case blockTypeImage:
          return <ImageBlockView block={props.block} />;
        case blockTypeTableCell:
          throw new Error('TableCellBlock is not a leaf block');
        case blockTypeEmbedLink:
          return <EmbedLinkBlockView block={props.block} />;
        default:
          exhaustiveCheck(props.block);
      }
    })()}
  </div>
));

type ListNodeViewProps = Readonly<{
  block: BlockJSON;
}>;
const ListNodeView = component$<ListNodeViewProps>((props) => (
  <li>
    <LeafBlockView block={props.block} isList />
    {(props.block.children ?? []).length > 0 && (
      <ul class={[ulClass, ulNonTopLevelClass]}>
        {(props.block.children ?? []).map((child) => (
          <ListNodeView key={child.id} block={child} />
        ))}
      </ul>
    )}
  </li>
));

type BlockViewProps = Readonly<{
  block: OutlineBlock;
  ctx: BlockViewContextValue;
}>;
export const BlockView = component$<BlockViewProps>((props) => {
  useContextProvider(BlockViewContext, props.ctx);
  return <BlockView_ block={props.block} />;
});

const BlockView_ = component$<Omit<BlockViewProps, 'ctx'>>((props) => {
  const block = props.block;
  switch (block.__typename) {
    case 'OutlineSection':
      return (
        <section class={blockViewSectionClass} data-block-id={block.header.id}>
          <header>
            <LeafBlockView block={block.header} />
          </header>
          {block.contents.map((block) => (
            <BlockView_ key={outlineBlockKey(block)} block={block} />
          ))}
        </section>
      );
    case 'ListRoot':
      return (
        <ul class={ulClass}>
          {block.children.map((block) => (
            <ListNodeView key={block.id} block={block} />
          ))}
        </ul>
      );
    default:
      return <LeafBlockView block={block} />;
  }
});

type BlocksViewProps = Readonly<{
  blocks: readonly OutlineBlock[];
  ctx: BlockViewContextValue;
}>;

export const BlocksView = component$<BlocksViewProps>((props) => {
  useContextProvider(BlockViewContext, props.ctx);
  return (
    <>
      {props.blocks.map((block) => (
        <BlockView_ key={outlineBlockKey(block)} block={block} />
      ))}
    </>
  );
});

const messages = {
  deletedPageTitle: '削除されたページ',
} as const;
